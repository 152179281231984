import Vue from 'vue';
import _ from 'lodash';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        token: null
    },
    getters: {
        username: state => {
            if (_.get(state, 'userInfo.preferred_username')) return state.userInfo.preferred_username;
            if (_.get(state, 'token.preferred_username')) return state.token.preferred_username;

            return 'you';
        },
        subject: state => {
            if (_.get(state, 'token.sub')) return state.token.sub;

            return null;
        }
    },
    mutations: {
        tokenUpdated(state, payload) {
            Vue.set(state, 'token', payload.parsed)
            Vue.set(state, 'tokenRaw', payload.raw)
        },
        userInfoUpdated(state, payload) {
            Vue.set(state, 'userInfo', payload)
        }
    }
});
