<template>
	<div id="app">
		<Navbar />

		<div class="container">
			<h1>Hi, {{ name }}!</h1>
		</div>

		<EventLog />
	</div>
</template>

<script>
import Navbar from './Navbar.vue';
import EventLog from './EventLog.vue';

export default {
	components: {
		Navbar,
		EventLog
	},
	computed: {
		name(){
			return this.$store.getters.username;
		}
	}
}
</script>
