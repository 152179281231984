import Vue from 'vue';
import App from './components/App.vue';
import 'bootstrap';
import store from './store'
import Keycloak from 'keycloak-js'

// Prevent the console tip about running in development mode from showing up
Vue.config.productionTip = false

const keycloak = new Keycloak({
		url: process.env.MIX_KEYCLOAK_URL + 'auth',
		realm: process.env.MIX_KEYCLOAK_REALM,
		clientId: process.env.MIX_KEYCLOAK_CLIENT
});

Object.defineProperty(Vue.prototype, '$keycloak', {
  get: function get() {
    return keycloak;
  }
});

keycloak.init({
  onLoad: 'login-required'
})

keycloak.onAuthSuccess = () => {
    store.commit('tokenUpdated', {
      parsed: keycloak.tokenParsed,
      raw: keycloak.token
    });
    
    // Don't render the application until the user is authenticated
    new Vue({
      el: '#app',
      template: '<App/>',
      components: { App },
      store,
    });

    if(Notification.permission === "default"){
      Notification.requestPermission()
    }
}

keycloak.onAuthError = (e) => {
  console.error(e);
}

setInterval(()=>{
  // Todo: proper refreshing 
  keycloak.updateToken(-1).success(function(refreshed) {
    if (refreshed) {
      store.commit('tokenUpdated', {
        parsed: keycloak.tokenParsed,
        raw: keycloak.token
      });
    } else {
      console.error('Token is still valid');
    }
  }).error(function() {
    console.error('Failed to refresh the token, or the session has expired');
  });
}, 60*1000);

