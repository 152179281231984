<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" href="/">Web<span style="color:#ff7d00">CTI</span></a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
      </ul>
      <button
        type="button"
        class="btn btn-danger"
        @click="$keycloak.logout"
        v-if="$keycloak.authenticated"
      >Log out</button>
    </div>
  </nav>
</template>

<script>
export default {};
</script>
