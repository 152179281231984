<template>
    <div class="container">
        <div class="card">
            <div class="card-body">
                <button v-if="Object.keys(this.events).length > 0" type="button" class="btn btn-sm btn-danger float-right" v-on:click="clear()">Clear</button>
                <h5 class="card-title">Event log</h5>

                <!-- Display all errors -->
                <div v-for="(message,index) in this.errors" :key="index">
                    <div class="alert alert-danger" role="alert">{{ message }}</div>
                </div>

                <!-- Display warning if no socket connection is active -->
                <div v-if="!this.connected" class="alert alert-danger" role="alert">{{ retryMessage }}</div>

                <!-- Display message incase there's no events yet -->
                <div v-if="Object.keys(this.events).length === 0" class="alert alert-info" role="alert">There are no events to display yet.</div>

                <!-- Display all events -->
                <div class="card-text">
                    <div v-for="(callEvents, callId, index) in this.events" :key="index">
                        <span v-bind:style="{background: stringToColour(callId)}" class="badge badge-secondary">
                          {{callEvents[0].call.source.number}}
                        </span>
                        <ul class="list-group">
                            <li v-for="(callEvent, index) in callEvents" :key="index" class="list-group-item">
                                <i v-if="callEvent.callEventType == 'start'" class="fas fa-network-wired"></i>
                                <i v-if="callEvent.callEventType == 'update' && callEvent.currentState.type == 'ringing'" class="fas fa-phone"></i>
                                <i v-if="callEvent.callEventType == 'update' && callEvent.currentState.type != 'ringing'" class="fas fa-microphone"></i>
                                <i v-if="callEvent.callEventType == 'end'" class="fas fa-phone-slash"></i> {{ callEvent.currentState ? callEvent.currentState.type : callEvent.reason }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { MessageHandler } from "../messageHandler";

let messageHandler = null;

export default {
  computed: {
    retryMessage: function() {
      return (
        "No socket connection active."
      );
    },
    ...mapState(["tokenRaw", "token"])
  },

  watch: {
    tokenRaw: function() {
      if (messageHandler != null) {
        messageHandler.setToken(this.tokenRaw);
      }
    }
  },
  data: () => {
    return {
      connected: false,
      retryInterval: 0,
      events: {},
      errors: []
    };
  },

  mounted() {
    messageHandler = new MessageHandler(process.env.MIX_EVENT_SENDER_URL);

    messageHandler.on("open", e => {
      this.connected = true;
    });
    
    messageHandler.on("close", e => {
      this.connected = false;
    });

    messageHandler.on("error", e => {
      if(e.message == undefined) return;
      let prev = this.errors;
      prev.push(e.message)
      this.$set(this, 'errors', prev)
    });

    messageHandler.on('event', e => {
      if(!(e.payload.call.callId in this.events)){
        this.$set(this.events, e.payload.call.callId, []);
      }
      
      let prev = this.events[e.payload.call.callId];
      prev.push(e.payload);
      this.$set(this.events, e.payload.call.callId, prev);

      if(e.type == "event" && e.payload.callEventType === 'start'){
        this.showNotification(
          `Inkomend: ${e.payload.call.source.number}`
        )
      }
    });

    this.connectToSocket();
  },
  methods: {
    connectToSocket: function() {
      messageHandler.setToken(this.tokenRaw);
      messageHandler.start();
    },
    clear: function(){
      this.$set(this, 'events', {})
    },
    stringToColour: function(str) {
        var hash = 0;
        for (var i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        var colour = '#';
        for (var i = 0; i < 3; i++) {
            var value = (hash >> (i * 8)) & 0xFF;
            colour += ('00' + value.toString(16)).substr(-2);
        }
        return colour;
    },
    showNotification: function(str){
      // Browser doesn't support notifications.
      if (!("Notification" in window)) return;

      if(Notification.permission !== "granted"){
        console.warn(`Not showing notification '${str}'. No permission granted by browser.`)
        return;
      }

      new Notification(str,{
        "icon":"https://www.speakup.nl/wp-content/uploads/2016/06/favicon-1.png"
      });
    }
  }
};
</script>
