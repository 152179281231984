import * as EventEmitter from 'events';
import * as uuid from 'uuid/v4';

class MessageHandler extends EventEmitter {
    constructor(webSocketUrl) {
        super();
        this.webSocketUrl = webSocketUrl;
    }

    start() {
        this.ws = new WebSocket(this.webSocketUrl);

        this.ws.onerror = e => this.emit('error', e);
        this.ws.onopen = e => this.emit('open', e);
        this.ws.onclose = e => this.emit('close', e); 

        this.ws.onmessage = e => {
            try {
                this.handleIncomingMessage(e.data)
            } catch (e) {
                this.emit('malformed-message', e);
            }

        }
    }

    setToken(token) {
        this.token = token;
    }

    validateMessage(data) {
        let message = data;
        if (typeof message === 'string') message = JSON.parse(data);

        if (typeof message.type === "undefined") throw new Error("Message doesn't contain the 'type' attribute.");
        if (typeof message.uuid === "undefined") throw new Error("Message doesn't contain the 'uuid' attribute.");
        if (typeof message.payload === "undefined") throw new Error("Message doesn't contain the 'payload' attribute.");

        return message;
    }

    handleIncomingMessage(jsonString) {
        let validatedMessage = this.validateMessage(jsonString);
        console.log(validatedMessage);

        switch (validatedMessage.type) {
            case 'token_request':
                this.sendToken();
                break;
            case 'event':                
                this.emit('event', validatedMessage);
                break;
            case 'error':
                this.emit('error', new Error(validatedMessage.payload.message))
        }
    }

    sendToken(){
        this.send({
            uuid: uuid(),
            type: "token",
            payload: {
                token: this.token
            }
        });
    }

    send(payload){
        console.log(payload);
        this.ws.send(JSON.stringify(payload));
    }
}

export { MessageHandler };
